import React from "react";
import { BiConversation } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWpforms } from "react-icons/fa";
import { BiMessageDots } from "react-icons/bi";
import { Link } from "gatsby";

const Messaging = ({ tel }) => {
	const [buttonShow, setModalShow] = React.useState(false);
	const telNum = tel || "1273 465 336";

	return (
		<>
			{/* <MessagingIcon/>
            <CallIcon/>
            <WhatsappIcon/> */}
			<a
				onClick={() => setModalShow(!buttonShow)}
				className="floating-icon floating-messaging d-md-none show-icons-icon"
			>
				<BiConversation
					title="contact button"
					style={{ marginTop: "18px", fontSize: "1.5rem" }}
				/>
			</a>

			<a
				href={`tel:+44${telNum}`}
				className="floating-call floating-icon d-md-none cta-icon"
				style={{ display: buttonShow ? "block" : "none" }}
				id="Call Icon"
			>
				<FaPhoneAlt style={{ marginTop: "18px", fontSize: "1.5rem" }} />
			</a>

			<a
				href="https://wa.me/447710408414"
				className="floating-whatsapp floating-icon d-md-none cta-icon"
				style={{ display: buttonShow ? "block" : "none" }}
				id="Whatsapp Icon"
			>
				<FaWhatsapp
					title="Whatsapp Icon"
					style={{ marginTop: "14px", fontSize: "2rem" }}
				/>
			</a>
			<a
				href="sms:07710408414"
				className="floating-textmessage floating-icon d-md-none cta-icon"
				style={{ display: buttonShow ? "block" : "none" }}
				id="Sms Icon"
			>
				<BiMessageDots style={{ marginTop: "15px", fontSize: "2rem" }} />
			</a>
			<Link
				to="/contact-us#form"
				className="floating-form floating-icon d-md-none contact-form-icon"
				style={{ display: buttonShow ? "block" : "none" }}
			>
				<FaWpforms style={{ marginTop: "15px", fontSize: "2rem" }} />
			</Link>
		</>
	);
};

export default Messaging;
